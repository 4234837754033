"use client";

import {useScrollTop} from "@/hooks/use-scroll-top";
import {cn} from "@/lib/utils";
import {Logo} from "./logo";
import {Button} from "@/components/ui/button";
import Link from "next/link";

import {
    NavigationMenu,
    NavigationMenuContent,
    NavigationMenuItem,
    NavigationMenuList,
    NavigationMenuTrigger
} from "@/components/ui/navigation-menu";
import React from "react";
import {NavigationBarMenuElementModel, NavigationBarModel} from "@/lib/models/NavigationBarModel";
import {useContentfulLiveUpdates} from "@contentful/live-preview/react";
import {ContentfulLivePreview} from "@contentful/live-preview";
import {Menu, X} from "lucide-react";
import {ScrollArea} from "@/components/ui/scroll-area";
import {Sheet, SheetClose, SheetContent, SheetFooter, SheetTrigger} from "@/components/ui/sheet";
import Image from "next/image";

export const NavbarComponent = ({navigationBarModel, isAuthenticated = false}: {
    navigationBarModel: NavigationBarModel | null;
    isAuthenticated?: boolean
}) => {
    const scrolled = useScrollTop();

    const updatedNavigationBarModel = useContentfulLiveUpdates(navigationBarModel);

    if (updatedNavigationBarModel == null) {
        return null;
    }

    return (
        <div
            className={cn(
                "z-50 bg-background-900 sticky top-0 flex flex-col items-center w-full ",
                scrolled && "border-b shadow-sm"
            )}>
            <div className="hidden publicmenubreak:flex justify-between items-center w-full max-w-[1440px] p-6">
                {updatedNavigationBarModel && updatedNavigationBarModel.logo && updatedNavigationBarModel.logo.url && (
                    <div>
                        <Link href={"/"} >
                            <img src={updatedNavigationBarModel.logo.url} className="h-[40px]" />
                        </Link>
                    </div>

                )}
                <div
                    {...ContentfulLivePreview.getProps({
                        entryId: updatedNavigationBarModel.sys.id,
                        fieldId: "navigationBar",
                        locale: "en-US",
                    })}
                    className="ml-auto justify-end w-full flex items-center gap-x-2">
                    {updatedNavigationBarModel && updatedNavigationBarModel.menuItemsCollection && updatedNavigationBarModel.menuItemsCollection.items.map((menuItem) => {
                        if (!menuItem || !menuItem.menuElementsCollection || menuItem.menuElementsCollection.total < 1) {
                            return null;
                        }

                        if (menuItem.menuElementsCollection.total == 1) {
                            const menuElement = menuItem.menuElementsCollection.items[0] as NavigationBarMenuElementModel;
                            return (
                                <Button
                                    {...ContentfulLivePreview.getProps({
                                        entryId: menuItem.sys.id,
                                        fieldId: "navigationBar",
                                        locale: "en-US",
                                    })}
                                    key={menuElement.sys.id}
                                    variant="darkText"
                                    size="sm">
                                    <Link href={menuElement.slug}>{menuElement.pageTitle}</Link>
                                </Button>
                            )
                        }

                        if (menuItem.menuElementsCollection.total > 1) {
                            return (
                                <NavigationMenu
                                    {...ContentfulLivePreview.getProps({
                                        entryId: menuItem.sys.id,
                                        fieldId: "navigationBar",
                                        locale: "en-US",
                                    })}
                                    key={menuItem.sys.id}>
                                    <NavigationMenuList>
                                        <NavigationMenuItem>
                                            <NavigationMenuTrigger
                                                className="font-normal text-secondary-200 disabled:opacity-40"
                                            >{menuItem.menuTitle}</NavigationMenuTrigger>
                                            <NavigationMenuContent>
                                                <ul className="flex flex-col gap-3 p-2 md:w-[300px] lg:w-[300px] font-light">
                                                    {menuItem && menuItem.menuElementsCollection &&
                                                        menuItem.menuElementsCollection.items &&
                                                        menuItem.menuElementsCollection.items.map((menuElement) => {
                                                            return (
                                                                <li key={menuElement.sys.id}>
                                                                    <Link
                                                                        href={menuElement.slug}
                                                                        className="block select-none space-y-1 rounded-md py-4 px-3 leading-none no-underline outline-none transition-colors hover:bg-secondary-200 hover:text-primary-900 focus:bg-accent focus:text-accent-foreground">
                                                                        <div
                                                                            className="text-sm font-medium leading-none">{menuElement.pageTitle}</div>
                                                                        {/* <p className="line-clamp-2 text-sm leading-snug text-secondary-200 text-opacity-80 ">
                                                                        {menuElement.pageDescription}
                                                                    </p> */}
                                                                    </Link>
                                                                </li>
                                                            )
                                                        })}
                                                </ul>
                                            </NavigationMenuContent>
                                        </NavigationMenuItem>
                                    </NavigationMenuList>
                                </NavigationMenu>
                            )
                        }

                    })}
                    {isAuthenticated && (
                        <Link href="/platform">
                            <Button variant={"darkFilled"} size={"md"} key={"platform_button"}>
                                Sign In
                            </Button>
                        </Link>

                    ) || (
                        <>
                            <Link href="/register">
                                <Button variant={"darkFilled"} size={"md"} key={"signup_button"}>
                                    Try for Free
                                </Button>
                            </Link>
                            <Link href="/login">
                                <Button
                                    className="ml-4"
                                    variant={"darkOutline"}
                                    size={"md"}
                                    key={"signin_button"}>
                                    Sign In
                                </Button>
                            </Link>
                        </>
                    )}
                </div>
            </div>
            <div className="publicmenubreak:hidden flex flex-row h-[88px] w-full px-6">
                <div className="flex flex-row items-center">
                    <Link href={"/"}>
                        <img src={updatedNavigationBarModel.logo.url} className="h-[40px]"/>
                    </Link>
                </div>
                <div className="flex flex-row items-end justify-end w-full">
                    <Sheet>

                        <SheetTrigger>
                            <div className="flex flex-col items-center justify-center h-[88px] ">
                                <Menu size={24} className="m-6"/>
                            </div>
                        </SheetTrigger>
                        <SheetContent side={"right"} className="p-0 ">
                            <ScrollArea className="h-full">
                                <div className="h-safe"></div>
                                <div className={"p-6 flex gap-6 flex-col"}>
                                    <Link
                                        className="flex items-center gap-x-2 h-[32px]"
                                        href={"/"}>
                                        <Image src="/cmn_logo_light_green.svg" alt="logo" height={32} width={130}/>
                                    </Link>


                                    {updatedNavigationBarModel && updatedNavigationBarModel.menuItemsCollection && updatedNavigationBarModel.menuItemsCollection.items.map((menuItem) => {
                                        return (

                                            <div className="w-full" key={menuItem.sys.id}>
                                                <div className="font-bold tracking-tighter text-neutral-500 mb-2">
                                                    {menuItem.menuTitle}
                                                </div>
                                                {menuItem.menuElementsCollection.items.map((menuElement) => (
                                                    <Link
                                                        key={menuElement.sys.id}
                                                        href={menuElement.slug}
                                                        className="flex flex-row items-center justify-between py-1"
                                                    >
                                                        <div className="text-lg text-neutral-100">
                                                            {menuElement.pageTitle}
                                                        </div>
                                                    </Link>
                                                ))}
                                            </div>

                                        )
                                    })}

                                </div>

                                <SheetFooter className="mt-auto flex flex-col gap-2 p-4 w-full">
                                    {isAuthenticated && (
                                        <Link href="/platform">
                                            <Button variant={"darkFilled"} size={"lg"} key={"platform_button"}
                                                    className={"flex w-full"}>
                                                Sign In
                                            </Button>
                                        </Link>

                                    ) || (
                                        <>
                                            <Link href="/register">
                                                <Button variant={"darkFilled"} size={"lg"} key={"signup_button"}
                                                        className={"flex w-full"}>
                                                    Try for Free
                                                </Button>
                                            </Link>
                                            <Link href="/login">
                                                <Button className={"flex w-full"}
                                                        variant={"darkOutline"}
                                                        size={"lg"}
                                                        key={"signin_button"}>
                                                    Sign In
                                                </Button>
                                            </Link>
                                        </>
                                    )}
                                    {/*<DrawerClose>
                                            <Button className="w-full mt-4" variant="darkText">Close</Button>
                                        </DrawerClose>*/}
                                </SheetFooter>
                            </ScrollArea>

                            <SheetClose>
                                <X/> CC
                            </SheetClose>
                        </SheetContent>
                    </Sheet>
                </div>
            </div>
        </div>
    )
}